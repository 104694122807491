import { z } from 'zod';
import { API_DATE_FORMAT } from '~/constants/_constants';
import { zDbId, zGuid, zLuxonDateTime } from '~/plugins/zod/utils';
import { getFullName } from '~/utils/userUtils';

// @plachtova: models are in special file because we had circular dependency problem
export const LanguageCodeSchema = z.string().length(2);
export const CountryCodeSchema = z.string().length(2);
export const CurrencyCodeSchema = z.string().length(3);

export const InstitutionLinkSchema = z.object({
    Id: zDbId(),
    DisplayId: zDbId(),
    Name: z.string(),
    Rating: z.string().nullish(),
    PrimaryLanguageCode: LanguageCodeSchema,
    Languages: LanguageCodeSchema.array(),
    FirstDayOfInstitution: zLuxonDateTime({ format: API_DATE_FORMAT }),
});

export const AppUserLinkSchema = z
    .object({
        Id: zDbId(),
        FirstName: z.string(),
        LastName: z.string(),
    })
    .transform((val) => ({
        ...val,
        FullName: getFullName(val),
    }));

export const ProgramLinkSchema = z.object({
    Id: zDbId(),
    DisplayId: zDbId(),
    Name: z.string(),
    Rating: z.string().nullish(),
});

export const StudentDataSchema = z
    .object({
        Uuid: zGuid(),
        DegreeBefore: z.string().nullable(),
        FirstName: z.string(),
        LastName: z.string(),
        DegreeAfter: z.string().nullable(),
        Birthday: zLuxonDateTime({ format: API_DATE_FORMAT }).nullable(),
        Email: z.string().nullable(),
    })
    .transform((data) => ({
        ...data,
        FullName: getFullName(data),
    }));

export type CountryCode = z.infer<typeof CountryCodeSchema>;
export type LanguageCode = z.infer<typeof LanguageCodeSchema>;
export type CurrencyCode = z.infer<typeof CurrencyCodeSchema>;
export type InstitutionLink = z.infer<typeof InstitutionLinkSchema>;
export type AppUserLink = z.infer<typeof AppUserLinkSchema>;
export type ProgramLink = z.infer<typeof ProgramLinkSchema>;
export type StudentData = z.infer<typeof StudentDataSchema>;
