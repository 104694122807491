import { z } from 'zod';
import { AuthTokenSchema, AuthUserSchema } from '~/plugins/auth/_types';
import config from '~/plugins/config';
import { zPasswordMatch } from '~/plugins/zod/utils';

export const AuthLoginResponseSchema = AuthTokenSchema.and(
    z.object({
        User: AuthUserSchema,
    })
);

export const AuthLoginFormDataSchema = z.object({
    Login: z.string().default(config.dev.login),
    Password: z.string().default(config.dev.password),
});

export const ForgotPasswordFormDataSchema = z.object({
    Email: z.string().email(),
});

export const ResetPasswordFormDataSchema = z
    .object({
        Password: z.string().min(8),
        PasswordConfirm: z.string().min(8),
    })
    .superRefine((arg, ctx) => zPasswordMatch(arg.Password, arg.PasswordConfirm, ctx, ['PasswordConfirm']));

export const ChangePasswordFormDataSchema = z
    .object({
        OldPassword: z.string(),
        NewPassword: z.string().min(8),
        NewPasswordConfirm: z.string().min(8),
    })
    .superRefine((arg, ctx) => zPasswordMatch(arg.NewPassword, arg.NewPasswordConfirm, ctx, ['NewPasswordConfirm']));

export const ValidateTokenResponseSchema = z.object({
    Login: z.string(),
});

export type AuthLoginResponse = z.infer<typeof AuthLoginResponseSchema>;
export type AuthLoginFormData = z.infer<typeof AuthLoginFormDataSchema>;
export type ForgotPasswordFormData = z.infer<typeof ForgotPasswordFormDataSchema>;
export type ResetPasswordFormData = z.infer<typeof ResetPasswordFormDataSchema>;
export type ChangePasswordFormData = z.infer<typeof ChangePasswordFormDataSchema>;
export type ValidateTokenResponse = z.infer<typeof ValidateTokenResponseSchema>;
