import { AppNavigationInfo } from './_types';

export const getNavigationConfig = (): Record<string, AppNavigationInfo> => {
    const { canManage: canAdminManage } = usePermissionsAdmin();
    const { canOverview: canInstitutionsOverview } = usePermissionsInstitutions();
    const { canOverview: canProgramsOverview } = usePermissionsPrograms();
    const { canOverview: canOrdersOverview } = usePermissionsOrders();
    const { canOverview: canQueueOverview } = usePermissionsQueue();
    const { canOverview: canCertificatesOverview } = usePermissionsQueue();

    return {
        home: {
            title: 'navigation.home.title',
            to: { name: 'index' },
            icon: 'las la-home',
        },
        institutions: {
            title: 'navigation.institutions.title',
            to: { name: 'institutions' },
            can: canInstitutionsOverview,
        },
        programs: {
            title: 'navigation.programs.title',
            to: { name: 'programs' },
            can: canProgramsOverview,
        },
        orders: {
            title: 'navigation.orders.title',
            to: { name: 'orders' },
            can: canOrdersOverview,
        },
        certificates: {
            title: 'navigation.certificates.title',
            to: { name: 'certificates' },
            can: canCertificatesOverview,
        },
        queue: {
            title: 'navigation.queue.title',
            to: { name: 'print-queue' },
            can: canQueueOverview,
        },
        admin: {
            title: 'navigation.admin.title',
            to: { name: 'admin' },
            can: canAdminManage,
            children: [
                {
                    title: 'navigation.admin.users.title',
                    to: { name: 'admin-users' },
                    icon: 'las la-users-cog',
                    subtitle: 'navigation.admin.users.subtitle',
                },
                {
                    title: 'navigation.admin.files.title',
                    to: { name: 'admin-files' },
                    icon: 'las la-folder-open',
                    subtitle: 'navigation.admin.files.subtitle',
                },
                {
                    title: 'navigation.admin.employers.title',
                    to: { name: 'admin-employers' },
                    icon: 'las la-user-tie',
                    subtitle: 'navigation.admin.employers.subtitle',
                },
                {
                    title: 'navigation.admin.languages.title',
                    to: { name: 'admin-languages' },
                    icon: 'las la-language',
                    subtitle: 'navigation.admin.languages.subtitle',
                },
                {
                    title: 'navigation.admin.countries.title',
                    to: { name: 'admin-countries' },
                    icon: 'las la-globe',
                    subtitle: 'navigation.admin.countries.subtitle',
                },
                {
                    title: 'navigation.admin.announcements.title',
                    to: { name: 'admin-announcements' },
                    icon: 'las la-bell',
                    subtitle: 'navigation.admin.announcements.subtitle',
                },
            ],
        },
    };
};
