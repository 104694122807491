<script lang="ts" setup>
import { getProgramsColumnConfig } from '~/configs/programs';
import { AppUserType } from '~/enums/permissions/_types';
import { ProgramsDashboardTableProps } from './_types';

const props = withDefaults(defineProps<ProgramsDashboardTableProps>(), {
    implicitFilter: null,
});

const { loggedUser } = useAuthUser();
const { isLoading, data, pagination, request } = usePrograms(
    { sortBy: 'UpdatedAt', descending: true, rowsPerPage: 15 },
    props.implicitFilter
);

const columns = getProgramsColumnConfig(false);

const visibleColumns = computed(() =>
    loggedUser.value?.Type === AppUserType.Client
        ? columns.filter((x) => x.name !== 'InstitutionName').map((x) => x.name)
        : undefined
);

onMounted(async () => {
    await request();
});
</script>

<template>
    <app-table
        v-model:pagination="pagination"
        sticky-column="last"
        :loading="isLoading"
        :columns="columns"
        :visible-columns="visibleColumns"
        :rows="data"
        row-key="DisplayId"
        :hide-bottom="data.length > 0"
        hide-pagination
        @request="request"
    >
        <template #body-cell-Name-tooltiped="scope">
            <q-td name="Name" :props="scope">
                <program-validity-flag-icon multiple :flags="scope.row.Flags" />
                {{ scope.col.field(scope.row) }}
            </q-td>
        </template>
        <template #body-cell-State="scope">
            <q-td name="State" :props="scope" class="text-bold" :class="`enum-text-programState--${scope.row.State}`">{{
                $t(`enums.programState.${scope.row.State}`)
            }}</q-td>
        </template>
        <template #body-cell-Actions="scope">
            <q-td key="Actions" :props="scope">
                <flat-btn
                    dense
                    :tooltip="$t('generic.tooltips.detail')"
                    icon="las la-file-alt"
                    :to="{ name: 'programs-id', params: { id: scope.row.DisplayId } }"
                />
            </q-td>
        </template>
    </app-table>
</template>
