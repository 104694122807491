import { DateTime } from 'luxon';
import { z, defaultErrorMap } from 'zod';
import { debugError } from '~/utils/debugUtils';
import { ApiZodSchemaResponseTransformer, ApiZodSchemaResponseTransformerFn } from './_types';

/**
 * Transform method for api client plugin
 * @param schema Zod Schema
 * @param source source - identifier for for tracking error (only for debug purposes)
 * @param data data from api client
 * @returns parsed data validated by schema or null if parsing failed
 */
export const transformZodSchema: ApiZodSchemaResponseTransformer = (schema, source, data) => {
    try {
        return schema.parse(data, { errorMap: defaultErrorMap });
    } catch (err) {
        console.error(`Zod schema transform error for api '${source}':`);
        debugError(`Data: `, data);
        debugError(`Zod Error: `, err);

        return null;
    }
};

/**
 * Transform method callback for api client plugin
 * @param schema Zod Schema
 * @param source source (identifier for for tracking error)
 */
export const transformZodSchemaFn: ApiZodSchemaResponseTransformerFn = (schema, path) =>
    transformZodSchema.bind(null, schema, path);

/**
 * Zod schema utils shorcut method for db id
 * @returns zod schema for db id
 */
export const zDbId = () => z.number().min(1);

/**
 * Zod schema utils shorcut method for guid
 * @returns zod schema for guid
 */
export const zGuid = () => z.string().uuid();

/**
 * Zod schema utils shorcut method for non empty string
 * @returns zod schema for non empty string
 */
export const zNonEmptyString = () => z.string().min(1);

/**
 * Zod schema utils metod for custom luxon DateTime type
 * @param opt - options (if input value is string)
 * @returns zod schema for luxon DateTime type
 */
export const zLuxonDateTime = (opt?: { iso?: boolean; format?: string }) =>
    // @plachtova: z.custom<DateTime>() is slightly incorrect because we do not do any validation in `custom` method
    // the reason we do validation in `transform` instead of `custom` metod that it has `ctx` there (needed for localization of validation mgs)
    // the only problematic thing is that parameter `arg` in `transform` has incorrect type (alrady has `DateTime` type but that does not have to be correct)
    // we must do this otherwise we will have type problems in forms (they use `z.input` for getting type)
    z.custom<DateTime>().transform((arg, ctx) => {
        if (arg instanceof DateTime) {
            return _zValidateLuxonDateTime(arg, ctx);
        }

        if (typeof arg === 'string' && opt?.iso) {
            return _zValidateLuxonDateTime(DateTime.fromISO(arg), ctx);
        }

        if (typeof arg === 'string' && opt?.format) {
            return _zValidateLuxonDateTime(DateTime.fromFormat(arg, opt.format), ctx);
        }

        ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            expected: 'date',
            received: typeof arg,
            fatal: true,
        });

        return z.NEVER;
    });

const _zValidateLuxonDateTime = (arg: DateTime, ctx: z.RefinementCtx) => {
    if (arg.isValid) return arg;

    ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        fatal: true,
    });
    return z.NEVER;
};

const _zLuxonDateTimeMin = (
    type: 'date' | 'datetime',
    min: DateTime,
    erorMsg: string | null,
    arg: DateTime,
    ctx: z.RefinementCtx
) => {
    if (arg < min) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                message: erorMsg || 'zod.errors.too_small.date.inclusive',
                minimum: min.toDefaultLocaleString(type === 'date' ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT),
            },
        });
    }
};

const _zLuxonDateTimeMax = (
    type: 'date' | 'datetime',
    max: DateTime,
    erorMsg: string | null,
    arg: DateTime,
    ctx: z.RefinementCtx
) => {
    if (arg > max) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                message: erorMsg || 'zod.errors.too_big.date.inclusive',
                maximum: max.toDefaultLocaleString(type === 'date' ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT),
            },
        });
    }
};

/**
 * Zod utils method for luxon DateTime - validation min date
 * Must be used as callback inside 'superRefine` method
 * @param min - min value to compare
 * @param erorMsg - custom error message (translation key supported)
 * @param arg - val (param from 'superRefine' method)
 * @param ctx - ctx (param from 'superRefine' method)
 */
export const zLuxonDateMin = _zLuxonDateTimeMin.bind(null, 'date');

/**
 * Zod utils method for luxon DateTime - validation min datetime
 * Must be used as callback inside 'superRefine` method
 * @param min - min value to compare
 * @param erorMsg - custom error message (translation key supported)
 * @param arg - val (param from 'superRefine' method)
 * @param ctx - ctx (param from 'superRefine' method)
 */
export const zLuxonDateTimeMin = _zLuxonDateTimeMin.bind(null, 'datetime');

/**
 * Zod utils method for luxon DateTime - validation max date
 * Must be used as callback inside 'superRefine` method
 * @param min - min value to compare
 * @param erorMsg - custom error message (translation key supported)
 * @param arg - val (param from 'superRefine' method)
 * @param ctx - ctx (param from 'superRefine' method)
 */
export const zLuxonDateMax = _zLuxonDateTimeMax.bind(null, 'date');

/**
 * Zod utils method for luxon DateTime - validation max datetime
 * Must be used as callback inside 'superRefine` method
 * @param min - min value to compare
 * @param erorMsg - custom error message (translation key supported)
 * @param arg - val (param from 'superRefine' method)
 * @param ctx - ctx (param from 'superRefine' method)
 */
export const zLuxonDateTimeMax = _zLuxonDateTimeMax.bind(null, 'datetime');

/**
 * Zod utils method - transform schema to nullish and assign default value if null or undefined
 * @param schema - zod schema
 * @param defaultVal - min value to compare
 */
export const zNullishDefault = <T>(schema: z.ZodSchema<T>, defaultVal: Required<T>) =>
    schema.nullish().transform((val) => val ?? defaultVal);

/**
 * Zod schema utils metod for timestamp (from date string)
 * @returns zod schema for timestamp (date i ms)
 */
export const zTimestamp = () =>
    z.custom<number>().transform((arg, ctx) => {
        const timestamp = new Date(arg).getTime();
        if (isNaN(timestamp)) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'number',
                received: typeof arg,
                fatal: true,
            });
        }

        return timestamp;
    });

export const zMatch = (
    val1: unknown,
    val2: unknown,
    ctx: z.RefinementCtx,
    path: (string | number)[],
    errorMsg?: string
) => {
    if (val1 === val2) return z.NEVER;

    ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path,
        params: {
            message: errorMsg || 'zod.custom.no_match',
        },
    });
};

export const zPasswordMatch = (
    password: string,
    passwordConfirm: string,
    ctx: z.RefinementCtx,
    path: (string | number)[],
    errorMsg?: string
) => zMatch(password, passwordConfirm, ctx, path, errorMsg ?? 'zod.custom.password_no_match');

/**
 * Zod schema utils metod for custom File type (for upload files)
 * @param errorMsg
 * @returns
 */
export const zFile = (errorMsg?: string) =>
    z.custom<unknown>().transform((arg, ctx) => {
        if (arg instanceof File) {
            return arg;
        }

        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                message: errorMsg || 'zod.custom.invalid_file',
            },
            fatal: true,
        });

        return z.NEVER;
    });
