<script lang="ts">
// @plachtova - must be in separate normal script tag, otherwise it cannot be used with `defineProps`
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [20, 50, 100] as const;
const DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE_OPTIONS[0];
const DEFAULT_PAGE_NUMBER = 1;
</script>

<script lang="ts" setup>
import { AppTablePagination, AppTableProps } from './_types';
import { QTable, QTableProps, QTableSlots } from 'quasar';
import { omit } from 'lodash-es';

const props = withDefaults(defineProps<AppTableProps>(), {
    noRowHover: true,
    flat: true,
    square: true,
    stripedRows: 'odd',
    rowsPerPageOptions: () => DEFAULT_ROWS_PER_PAGE_OPTIONS,
});

const emit = defineEmits<{
    (e: 'update:pagination', data: AppTablePagination | undefined): void;
    (e: 'update:selected', data: Parameters<Required<QTableProps>['onUpdate:selected']>[0]): void;
    (e: 'request', data: Parameters<Required<QTableProps>['onRequest']>[0]): void;
}>();

defineSlots<QTableSlots>();

const tableRef = ref<QTable>();
const tableRowsPerPage = ref<number>(DEFAULT_ROWS_PER_PAGE);
const tablePageNumber = ref(DEFAULT_PAGE_NUMBER);

const tablePagination = ref<AppTablePagination>({});

watchEffect(
    () =>
        (tablePagination.value = {
            descending: props.pagination?.descending ?? false,
            sortBy: props.pagination?.sortBy ?? null,
            rowsNumber: props.pagination?.rowsNumber,
            page: props.pagination?.page ?? DEFAULT_PAGE_NUMBER,
            rowsPerPage: props.pagination?.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE,
        })
);

watch(tablePagination, (newVal, oldVal) => {
    if (oldVal?.rowsPerPage !== newVal?.rowsPerPage) {
        tableRowsPerPage.value = newVal?.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE;
    }

    if (oldVal?.page !== newVal?.page) {
        tablePageNumber.value = newVal?.page ?? DEFAULT_PAGE_NUMBER;
    }
});

const onPaginationUpdate: QTableProps['onUpdate:pagination'] = (data) => {
    emit('update:pagination', data);
};

const onSelectedUpdate: QTableProps['onUpdate:selected'] = (data) => {
    emit('update:selected', data);
};

const onRequest: QTableProps['onRequest'] = (data) => {
    tablePagination.value = {
        ...data.pagination,
    };
    emit('update:pagination', tablePagination.value);
    emit('request', data);
};

const onPageNumberUpdate = (val: number) => {
    if (tablePagination.value?.rowsNumber) {
        tableRef.value?.requestServerInteraction({
            pagination: { ...tablePagination.value, page: val, sortBy: tablePagination.value.sortBy ?? undefined },
        });
    } else {
        tablePagination.value = {
            ...tablePagination.value,
            page: val,
        };
    }
};

const onRowsPerPageUpdate = (val: number) => {
    if (tablePagination.value?.rowsNumber) {
        tableRef.value?.requestServerInteraction({
            pagination: {
                ...tablePagination.value,
                rowsPerPage: val,
                sortBy: tablePagination.value.sortBy ?? undefined,
            },
        });
    } else {
        tablePagination.value = {
            ...tablePagination.value,
            rowsPerPage: val,
        };
    }
};

const slots = useSlots();
const tooltipedSlots = computed(() =>
    Object.keys(slots)
        .filter((v: string) => v.includes('tooltiped'))
        .map((v) => ({ slot: v.replace('-tooltiped', ''), tooltipedName: v }))
);
</script>

<template>
    <q-table
        ref="tableRef"
        v-bind="omit(props, ['pagination'])"
        v-model:pagination="tablePagination"
        class="app-table"
        :no-data-label="noDataLabel ? noDataLabel : $t('components.table.noDataLabel')"
        :class="{
            'app-table--no-q-hover': props.noRowHover,
            'app-table--col-sticky_last': props.stickyColumn === 'last',
            'app-table--col-sticky_first': props.stickyColumn === 'first',
            'app-table--row-striped-odd': props.stripedRows === 'odd',
            'app-table--row-striped-even': props.stripedRows === 'even',
        }"
        @update:selected="onSelectedUpdate"
        @update:pagination="onPaginationUpdate"
        @request="onRequest"
    >
        <template v-for="slotName in tooltipedSlots" :key="slotName.tooltipedName" #[slotName.slot]="scope">
            <td :props="scope">
                <span class="single-line">
                    <!-- @vue-ignore -->
                    <slot :name="slotName.tooltipedName" v-bind="scope"></slot>
                </span>
                <q-tooltip anchor="top middle">
                    <!-- @vue-ignore -->
                    <slot :name="slotName.tooltipedName" v-bind="scope"></slot>
                </q-tooltip>
            </td>
        </template>
        <template
            v-for="(_, slotName) in $slots as Readonly<QTableSlots>"
            :key="slotName"
            #[slotName]="// @ts-ignore
            slotProps"
        >
            <slot :name="slotName" v-bind="(slotProps ?? {}) as any"></slot>
        </template>
        <template #header="scope">
            <q-tr :props="scope">
                <q-th v-if="selection === 'single' || selection === 'multiple'" class="q-mx-xs" auto-width>
                    <q-checkbox v-model="scope.selected" />
                </q-th>
                <q-th v-for="col in scope.cols" :key="col.name" :props="scope">
                    <span class="a-text-15 text-bold">
                        {{
                            'translatedLabel' in col
                                ? $t(col.translatedLabel.key, col.translatedLabel.params)
                                : col.label
                        }}
                    </span>
                </q-th>
            </q-tr>
        </template>
        <template #no-data="scope">
            <slot name="no-data" v-bind="scope">
                <div class="a-text-14 text-italic">{{ scope.message }}</div>
            </slot>
        </template>
        <template #bottom="scope">
            <div v-if="!hidePagination" class="row col justify-end q-mx-none">
                <q-select
                    v-if="rowsPerPageOptions.length > 1"
                    v-model="tableRowsPerPage"
                    class="q-mr-md"
                    :options="rowsPerPageOptions"
                    dense
                    borderless
                    :menu-offset="[10, 0]"
                    popup-content-class="q-menu--square"
                    @update:model-value="onRowsPerPageUpdate"
                />
                <q-pagination
                    v-model="tablePageNumber"
                    class="table-pagination"
                    :max="scope.pagesNumber"
                    direction-links
                    boundary-links
                    boundary-numbers
                    :max-pages="5"
                    outline
                    unelevated
                    gutter="1px"
                    color="primary"
                    active-design="unelevated"
                    active-color="primary"
                    active-text-color="surface"
                    @update:model-value="onPageNumberUpdate"
                />
            </div>
        </template>
    </q-table>
</template>
