import { ProgramRevisionInfo } from '~/api/programs/_types';

export const useProgramRevisionsStore = defineStore('programRevisions', () => {
    const revisions = ref<ProgramRevisionInfo[] | null>(null);

    const reset = () => {
        revisions.value = null;
    };

    return {
        programRevisions: revisions,
        reset,
    };
});
