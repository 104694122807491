import { RefinementCtx } from 'zod';
import { InstitutionName, InstitutionNameFormData } from './institutions/_types';
import { REQUIRED_LANG } from '~/constants/_constants';
import { ProgramName, ProgramNameFormData } from './programs/_types';

export const mergeEntityName = (name: InstitutionNameFormData | ProgramNameFormData): string => {
    const lines = [name.Line1];

    name.Line2 != null && lines.push(name.Line2);
    'Line3' in name && name.Line3 != null && lines.push(name.Line3);

    return lines.join('\n');
};

export const splitEntityMultilineName = <T extends InstitutionName | ProgramName>(
    name: string | undefined,
    numOfLines = 3
): T | null => {
    const splitName = name?.split(/\r?\n/);

    if (!splitName?.length) return null;

    const base: Record<string, string | undefined> = {
        Orig: name,
    };

    for (let line = 1; line <= numOfLines; line++) {
        base[`Line${line}`] = splitName.shift();
    }

    return base as unknown as T;
};

export const validateEntityName = (
    entityNames: Record<string, string | undefined>,
    hasError: boolean,
    ctx: RefinementCtx
) => {
    for (const language in entityNames) {
        if (entityNames[language] != null) {
            continue;
        }

        ctx.addIssue({
            code: 'custom',
            message: `Name is empty for lang '${language}'`,
        });

        return true;
    }

    return hasError;
};

export const entityMultilineNameTransform = <T extends ProgramName | InstitutionName>(
    entityName: Record<string, string | undefined>,
    numOfLines = 3
) => {
    const splitName: Record<string, T> = {};

    for (const language in entityName) {
        const name = splitEntityMultilineName<T>(entityName[language], numOfLines);

        if (name != null) {
            splitName[language] = name;
            continue;
        }
    }

    return splitName;
};

export const validateRequiredName = (
    requiredName: InstitutionName | ProgramName | null,
    hasError: boolean,
    ctx: RefinementCtx
) => {
    if (requiredName == null) {
        ctx.addIssue({
            code: 'custom',
            message: `Name should have required lang '${REQUIRED_LANG}'`,
        });
        return true;
    }

    return hasError;
};
