import { z } from 'zod';
import { InstitututionState, InstitututionType } from '~/enums/_types';
import { zDbId, zLuxonDateTime, zNonEmptyString } from '~/plugins/zod/utils';
import { CountryLinkSchema, LanguageLinkSchema } from '~/api/enums/_types';
import { API_DATE_FORMAT, REQUIRED_LANG } from '~/constants/_constants';
import { AppUserLinkSchema, CountryCodeSchema, InstitutionLinkSchema, LanguageCodeSchema } from '~/api/links/_types';
import { entityMultilineNameTransform, validateEntityName, validateRequiredName } from '../utils';

export interface InstitutionName {
    Orig: string;
    Line1: string;
    Line2?: string;
    Line3?: string;
}

export const InstitutionAddressSchema = z.object({
    Street: z.string(),
    City: z.string(),
    Zip: z.string(),
});

export const InstitutionInfoSchema = z
    .object({
        Id: zDbId(),
        DisplayId: zDbId(),
        Name: z.record(LanguageCodeSchema, z.string()),
        Country: CountryLinkSchema,
        PrimaryLanguage: LanguageLinkSchema,
        Languages: z.array(z.string()),
        Rating: z.string(),
        Address: InstitutionAddressSchema,
        Type: z.nativeEnum(InstitututionType),
        State: z.nativeEnum(InstitututionState),
        CreatedAt: zLuxonDateTime({ iso: true }),
        CreatedBy: AppUserLinkSchema,
        UpdatedAt: zLuxonDateTime({ iso: true }),
        UpdatedBy: AppUserLinkSchema,
        // TODO: odstranit nullable? (zatim je problem v importu starych dat, kde tato hodnota nekdy neni)
        ValidFrom: zLuxonDateTime({ iso: true }).nullable(),
        ActivatedAt: zLuxonDateTime({ iso: true }).nullable(),
        ActivatedBy: AppUserLinkSchema.nullable(),
        FirstDayOfInstitution: zLuxonDateTime({ format: API_DATE_FORMAT }),
    })
    .transform((values, ctx) => {
        let hasError = false;

        //Prepare and validate institution name
        hasError = validateEntityName(values.Name, hasError, ctx);
        const splitName: Record<string, InstitutionName> = entityMultilineNameTransform(values.Name);

        const requiredName = splitName[REQUIRED_LANG];
        hasError = validateRequiredName(requiredName, hasError, ctx);

        return hasError
            ? z.NEVER
            : {
                  ...values,
                  NameRequired: requiredName,
                  Name: splitName,
              };
    });

export const InstitutionRevisionInfoSchema = z
    .object({
        Id: zDbId(),
        Name: z.record(LanguageCodeSchema, z.string()),
        PrimaryLanguageCode: LanguageCodeSchema,
        Address: InstitutionAddressSchema,
        ValidFrom: zLuxonDateTime({ iso: true }).nullable(),
        UpdatedAt: zLuxonDateTime({ iso: true }),
        UpdatedBy: AppUserLinkSchema,
        Rating: z.string(),
    })
    .transform((values, ctx) => {
        let hasError = false;

        //Prepare and validate institution name
        hasError = validateEntityName(values.Name, hasError, ctx);
        const splitName: Record<string, InstitutionName> = entityMultilineNameTransform(values.Name);

        const requiredName = splitName[REQUIRED_LANG];
        hasError = validateRequiredName(requiredName, hasError, ctx);

        return hasError
            ? z.NEVER
            : {
                  ...values,
                  NameRequired: requiredName,
                  Name: splitName,
              };
    });

export const CreateProgramRelatedInstitutionInfo = z.object({
    Id: zDbId(),
    PrimaryLanguageCode: LanguageCodeSchema,
    Languages: z.array(z.string()),
    FirstDayOfInstitution: zLuxonDateTime({ iso: true }),
});

export const InstitutionNameFormDataSchema = z.object({
    Line1: zNonEmptyString(),
    Line2: z.string().optional(),
    Line3: z.string().optional(),
});

export const InstitutionAddressFormDataSchema = z.object({
    Street: zNonEmptyString(),
    City: zNonEmptyString(),
    Zip: zNonEmptyString(),
});

export const InstitutionFormDataSchema = z.object({
    Name: z.record(LanguageCodeSchema, InstitutionNameFormDataSchema),
    Rating: zNonEmptyString(),
    CountryCode: CountryCodeSchema,
    PrimaryLanguageCode: LanguageCodeSchema,
    Type: z.nativeEnum(InstitututionType),
    Address: InstitutionAddressFormDataSchema,
    ValidFrom: zLuxonDateTime(),
});

export const InstitutionRevisionFormDataSchema = z.object({
    Name: z.record(LanguageCodeSchema, InstitutionNameFormDataSchema),
    Rating: zNonEmptyString(),
    PrimaryLanguageCode: LanguageCodeSchema,
    Address: InstitutionAddressFormDataSchema,
    ValidFrom: zLuxonDateTime(),
});

export const InstitututionChangeStateFormDataSchema = z.object({
    State: z.nativeEnum(InstitututionState),
});

export const FetchInstitutionsResponseSchema = z.array(InstitutionInfoSchema);
export const FetchEnumInstitutionsResponseSchema = z.array(InstitutionLinkSchema);
export const FetchInstitutionRevisionInfoResponseSchema = z.array(InstitutionRevisionInfoSchema);

export type InstitutionInfo = z.infer<typeof InstitutionInfoSchema>;
export type InstitutionRevisionInfo = z.infer<typeof InstitutionRevisionInfoSchema>;
export type FetchInstitutionsResponse = z.infer<typeof FetchInstitutionsResponseSchema>;
export type FetchEnumInstitutionsResponse = z.infer<typeof FetchEnumInstitutionsResponseSchema>;
export type FetchInstitutionRevisionInfoResponse = z.infer<typeof FetchInstitutionRevisionInfoResponseSchema>;
export type InstitutionFormData = z.infer<typeof InstitutionFormDataSchema>;
export type InstitutionNameFormData = z.infer<typeof InstitutionNameFormDataSchema>;
export type InstitutionAddressFormData = z.infer<typeof InstitutionAddressFormDataSchema>;
export type InstitutionRevisionFormData = z.infer<typeof InstitutionRevisionFormDataSchema>;
export type CreateProgramRelatedInstitutionInfo = z.infer<typeof CreateProgramRelatedInstitutionInfo>;
export type InstitututionChangeStateFormData = z.infer<typeof InstitututionChangeStateFormDataSchema>;
