import { CertficateInfo, CertificateDetail, CertificateLink } from '~/api/certificates/_types';
import { LanguageInfo } from '~/api/enums/_types';
import { StudentDetail } from '~/api/students/_types';
import { InformationCardItemConfig } from '~/components/base/cards/_types';
import { FilterCardColumnConfig, FilterDataType } from '~/components/base/filters/_types';
import { AppTableColumnConfig } from '~/components/base/tables/_types';
import { EMPTY_DATA_PLACEHOLDER } from '~/constants/_constants';
import { t } from '~/plugins/i18n';
import { Locale } from '~/plugins/i18n/_types';

export const getCertificatesOverviewColumnConfig = (canSort: boolean): AppTableColumnConfig[] => [
    {
        name: 'Id',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.Id' },
        field: 'Id',
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'Number',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.Number' },
        field: 'Number',
        align: 'left',
    },
    {
        name: 'Type',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.Type' },
        field: (val) => t(`enums.certificateType.${val.Type}`),
        align: 'left',
    },
    {
        name: 'LastName',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.Name' },
        field: (val: CertficateInfo) => val.Student.FullName,
        align: 'left',
    },
    {
        name: 'LanguageCode',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.Language' },
        field: (val: CertficateInfo) => val.LanguageCode.toUpperCase(),
        align: 'center',
    },
    {
        name: 'InstitutionName',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.InstitutionName' },
        field: (val: CertficateInfo) => val.Institution?.Name,
        align: 'left',
    },
    {
        name: 'ProgramName',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.ProgramName' },
        field: (val: CertficateInfo) => val.Program?.Name,
        align: 'left',
    },
    {
        name: 'OrderFinishedAt',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.FinishedAt.eq' },
        field: (val: CertficateInfo) => val.FinishedAt?.toDefaultLocaleString(),
        align: 'center',
        sortable: canSort,
    },
    {
        name: 'State',
        label: '',
        translatedLabel: { key: 'certificates.overview.columns.State' },
        field: 'State',
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getCertificatesOverviewFilterColumnConfig = (activeLocale: Ref<Locale>): FilterCardColumnConfig[] => [
    {
        dataType: FilterDataType.Number,
        mode: 'equals',
        key: 'Id',
        label: 'certificates.overview.columns.Id',
        additionalFilter: false,
    },
    {
        dataType: FilterDataType.Date,
        mode: 'range',
        key: 'OrderFinishedAt',
        labelFrom: 'certificates.overview.columns.FinishedAt.from',
        labelTo: 'certificates.overview.columns.FinishedAt.to',
        additionalFilter: false,
    },
    {
        dataType: FilterDataType.Enum,
        key: 'LanguageCode',
        label: 'certificates.overview.columns.Language',
        additionalFilter: false,
        multiple: true,
        optionsKey: 'Code',
        optionsLabelKey: (val: LanguageInfo) => val.Name[activeLocale.value] ?? '',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'InstitutionId',
        label: 'certificates.overview.columns.InstitutionName',
        additionalFilter: false,
        multiple: true,
        autocomplete: true,
        optionsKey: 'DisplayId',
        optionsLabelKey: 'Name',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'ProgramId',
        label: 'certificates.overview.columns.ProgramName',
        additionalFilter: false,
        multiple: true,
        autocomplete: true,
        optionsKey: 'DisplayId',
        optionsLabelKey: 'Name',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'State',
        label: 'certificates.overview.columns.State',
        additionalFilter: false,
        multiple: true,
    },
];

export const getStudentCertificatesOverviewColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'Id',
        label: '',
        translatedLabel: { key: 'certificates.student.columns.Id' },
        field: 'Id',
        align: 'left',
        sortable: true,
    },
    {
        name: 'Number',
        label: '',
        translatedLabel: { key: 'certificates.student.columns.Number' },
        field: (val) => val.Number ?? EMPTY_DATA_PLACEHOLDER,
        align: 'left',
        sortable: false,
    },
    {
        name: 'Type',
        label: '',
        translatedLabel: { key: 'certificates.student.columns.Type' },
        field: (val) => t(`enums.certificateType.${val.Type}`),
        align: 'center',
        sortable: false,
    },
    {
        name: 'Language',
        label: '',
        translatedLabel: { key: 'certificates.student.columns.Language' },
        field: (val: CertificateLink) => val.LanguageCode.toUpperCase(),
        align: 'center',
        sortable: false,
    },
    {
        name: 'State',
        label: '',
        translatedLabel: { key: 'certificates.student.columns.State' },
        field: 'State',
        align: 'left',
        sortable: true,
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getCertificatesQueueColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'Id',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.Id' },
        field: 'Id',
        align: 'left',
        sortable: true,
    },
    {
        name: 'Number',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.Number' },
        field: (val) => val.Number ?? EMPTY_DATA_PLACEHOLDER,
        align: 'left',
        sortable: false,
    },
    {
        name: 'Type',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.Type' },
        field: (val) => t(`enums.certificateType.${val.Type}`),
        align: 'center',
        sortable: false,
    },
    {
        name: 'LastName',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.Name' },
        field: (val: CertficateInfo) => val.Student.FullName,
        align: 'left',
        sortable: false,
    },
    {
        name: 'InstitutionName',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.InstitutionName' },
        field: (val: CertficateInfo) => val.Institution?.Name,
        align: 'left',
    },
    {
        name: 'ProgramName',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.ProgramName' },
        field: (val: CertficateInfo) => val.Program?.Name,
        align: 'left',
    },
    {
        name: 'OrderCreatedAt',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.CreatedAt' },
        field: (val: CertficateInfo) => val.CreatedAt.toDefaultLocaleString(),
        align: 'center',
        sortable: true,
    },
    {
        name: 'OrderFinishedAt',
        label: '',
        translatedLabel: { key: 'certificates.queue.columns.FinishedAt' },
        field: (val: CertficateInfo) => val.FinishedAt?.toDefaultLocaleString(),
        align: 'center',
        sortable: true,
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getCertificateInfoCardConfig = (certificate: CertificateDetail) => {
    const institution = certificate.Institution
        ? [
              {
                  label: t('certificates.detail.labels.institution'),
                  value: certificate.Institution.Name,
                  position: {
                      xs: { row: 3, col: 0 },
                      sm: { row: 2, col: 0 },
                      md: { row: 1, col: 0, span: 2 },
                  },
              },
              {
                  label: t('certificates.detail.labels.institutionRating'),
                  value: certificate.Institution.Rating ?? EMPTY_DATA_PLACEHOLDER,
                  position: {
                      xs: { row: 4, col: 0 },
                      sm: { row: 2, col: 1 },
                      md: { row: 1, col: 2, span: 2 },
                  },
              },
          ]
        : [];

    const program = certificate.Program
        ? [
              {
                  label: t('certificates.detail.labels.program'),
                  value: certificate.Program.Name,
                  position: {
                      xs: { row: 5, col: 0 },
                      sm: { row: 3, col: 0 },
                      md: { row: 2, col: 0, span: 2 },
                  },
              },
              {
                  label: t('certificates.detail.labels.programRating'),
                  value: certificate.Program.Rating ?? EMPTY_DATA_PLACEHOLDER,
                  position: {
                      xs: { row: 6, col: 0 },
                      sm: { row: 3, col: 1 },
                      md: { row: 2, col: 2, span: 2 },
                  },
              },
          ]
        : [];

    return [
        {
            label: t('certificates.detail.labels.number'),
            value: certificate.Number?.toString() ?? EMPTY_DATA_PLACEHOLDER,
            position: {
                xs: { row: 0, col: 0 },
                sm: { row: 0, col: 0 },
                md: { row: 0, col: 0, span: 2 },
            },
        },
        {
            label: t('certificates.detail.labels.type'),
            value: certificate.Type,
            position: {
                xs: { row: 1, col: 0 },
                sm: { row: 1, col: 0 },
                md: { row: 0, col: 2 },
            },
        },
        {
            label: t('certificates.detail.labels.language'),
            value: certificate.LanguageCode.toUpperCase(),
            position: {
                xs: { row: 2, col: 0 },
                sm: { row: 1, col: 1 },
                md: { row: 0, col: 3 },
            },
        },
        ...institution,
        ...program,
        {
            label: t('certificates.detail.labels.student'),
            value: certificate.Student.FullName,
            position: {
                xs: { row: 7, col: 0 },
                sm: { row: 4, col: 0 },
                md: { row: 3, col: 0 },
            },
        },
        {
            label: t('certificates.detail.labels.email'),
            value: certificate.Student?.Email ?? EMPTY_DATA_PLACEHOLDER,
            position: {
                xs: { row: 8, col: 0 },
                sm: { row: 4, col: 1 },
                md: { row: 3, col: 1 },
            },
        },
        {
            label: t('certificates.detail.labels.birthday'),
            value: certificate.Student.Birthday?.toDefaultLocaleString() ?? EMPTY_DATA_PLACEHOLDER,
            position: {
                xs: { row: 9, col: 0 },
                sm: { row: 5, col: 0 },
                md: { row: 3, col: 2 },
            },
        },
        {
            label: t('certificates.detail.labels.state'),
            value: t(`enums.certificateState.${certificate.State}`),
            valueClass: `text-bold a-text-22 enum-text-certificateState--${certificate.State}`,
            position: {
                xs: { row: 10, col: 0 },
                sm: { row: 5, col: 1 },
                md: { row: 3, col: 3 },
            },
        },
    ];
};

export const getCertificateStudentInfoCardConfig = (student: StudentDetail): InformationCardItemConfig[] => {
    const institution = student.Institution
        ? [
              {
                  label: t('certificates.student.labels.Institution'),
                  value: student.Institution.Name,
                  position: {
                      xs: { row: 3, col: 0 },
                      md: { row: 2, col: 0 },
                  },
              },
          ]
        : [];

    const program = student.Program
        ? [
              {
                  label: t('certificates.student.labels.Program'),
                  value: student.Program.Name,
                  position: {
                      xs: { row: 4, col: 0 },
                      md: { row: 2, col: 1 },
                  },
              },
          ]
        : [];

    return [
        {
            label: t('certificates.student.labels.Name'),
            value: student.Student.FullName,
            position: {
                xs: { row: 0, col: 0 },
                md: { row: 0, col: 0 },
            },
        },
        {
            label: t('certificates.student.labels.Birthday'),
            value: student.Student.Birthday?.toDefaultLocaleString() ?? EMPTY_DATA_PLACEHOLDER,
            position: {
                xs: { row: 1, col: 0 },
                md: { row: 1, col: 0 },
            },
        },
        {
            label: t('certificates.student.labels.Email'),
            value: student.Student.Email ?? EMPTY_DATA_PLACEHOLDER,
            position: {
                xs: { row: 2, col: 0 },
                md: { row: 1, col: 1 },
            },
        },
        ...institution,
        ...program,
        {
            label: t('certificates.student.labels.PublishAt'),
            value: student.Order.PublishAt.toDefaultLocaleString(),
            position: {
                xs: { row: 5, col: 0 },
                md: { row: 3, col: 0 },
            },
        },
        {
            label: t('certificates.student.labels.FinishedAt'),
            value: student.Order.FinishedAt.toDefaultLocaleString(),
            position: {
                xs: { row: 6, col: 0 },
                md: { row: 3, col: 1 },
            },
        },
    ];
};
