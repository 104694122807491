import { z } from 'zod';
import { REQUIRED_LANG } from '~/constants/_constants';
import { ProgramState } from '~/enums/_types';
import { zDbId, zGuid, zLuxonDateTime, zNonEmptyString } from '~/plugins/zod/utils';
import { AppFileInfoSchema } from '~/api/files/_types';
import { AppUserLinkSchema, InstitutionLinkSchema, LanguageCodeSchema, ProgramLinkSchema } from '~/api/links/_types';
import { entityMultilineNameTransform, validateEntityName, validateRequiredName } from '../utils';

export interface ProgramName {
    Orig: string;
    Line1: string;
    Line2?: string;
}

export const ProgramNameFormDataSchema = z.object({
    Line1: zNonEmptyString(),
    Line2: z.string().optional(),
});


const ProgramRevisionValidityFlags = z
    .object({
        NoValidInstitution: z.boolean().nullable(),
        LangsMisconfigured: z.boolean().nullable(),
    })
    .nullable();

export const ProgramInfoSchema = z
    .object({
        Id: zDbId(),
        DisplayId: zDbId(),
        Institution: InstitutionLinkSchema,
        Name: z.record(LanguageCodeSchema, z.string()),
        Rating: z.string().nullish(),
        CreatedAt: zLuxonDateTime({ iso: true }),
        UpdatedAt: zLuxonDateTime({ iso: true }),
        CertifiedAt: zLuxonDateTime({ iso: true }).nullable(),
        State: z.nativeEnum(ProgramState),
        Flags: ProgramRevisionValidityFlags,
    })
    .transform((values, ctx) => {
        let hasError = false;

        //Prepare and validate institution name
        hasError = validateEntityName(values.Name, hasError, ctx);
        const splitName: Record<string, ProgramName> = entityMultilineNameTransform(values.Name, 2);

        const requiredName = splitName[REQUIRED_LANG];
        hasError = validateRequiredName(requiredName, hasError, ctx);

        return hasError
            ? z.NEVER
            : {
                  ...values,
                  NameRequired: requiredName,
                  Name: splitName,
              };
    });

export const ProgramDetailSchema = ProgramInfoSchema.and(
    z.object({
        Duration: z.record(LanguageCodeSchema, z.string()),
        ValidFrom: zLuxonDateTime({ iso: true }).nullable(),
        ValidTo: zLuxonDateTime({ iso: true }).nullable(),
        CreatedBy: AppUserLinkSchema,
        UpdatedBy: AppUserLinkSchema,
        Competences: z.string(),
        NextStates: z.array(z.nativeEnum(ProgramState)),
        Languages: z.array(LanguageCodeSchema),
        Files: z.array(AppFileInfoSchema),
        Can: z.object({
            Update: z.boolean(),
            UpdateCompetences: z.boolean(),
            AppendFiles: z.boolean(),
        }),
    })
).transform((data, ctx) => {
    const requiredDuration = data.Duration[REQUIRED_LANG];
    if (requiredDuration == null) {
        ctx.addIssue({
            code: 'custom',
            message: `Duration should have required lang '${REQUIRED_LANG}'`,
        });
        return z.NEVER;
    }

    return {
        ...data,
        DurationRequired: requiredDuration,
    };
});

export const ProgramRevisionInfoSchema = z
    .object({
        Id: zDbId(),
        State: z.nativeEnum(ProgramState),
        DisplayId: zDbId(),
        // todo: odstranit optional, jak se zadne vracet z API
        Institution: InstitutionLinkSchema.optional(),
        Name: z.record(LanguageCodeSchema, z.string()),
        Rating: z.string().nullish(),
        ValidFrom: zLuxonDateTime({ iso: true }).nullable(),
        ValidTo: zLuxonDateTime({ iso: true }).nullable(),
        UpdatedAt: zLuxonDateTime({ iso: true }),
        UpdatedBy: AppUserLinkSchema,
        Duration: z.record(LanguageCodeSchema, z.string()),
        Flags: ProgramRevisionValidityFlags,
        Competences: z.string(),
    })
    .transform((data, ctx) => {

        const splitName: Record<string, ProgramName> = entityMultilineNameTransform(data.Name, 2);
        const requiredName = splitName[REQUIRED_LANG];

        if (requiredName == null) {
            ctx.addIssue({
                code: 'custom',
                message: `Name should have required lang '${REQUIRED_LANG}'`,
            });
            return z.NEVER;
        }

        const requiredDuration = data.Duration[REQUIRED_LANG];
        if (requiredDuration == null) {
            ctx.addIssue({
                code: 'custom',
                message: `Duration should have required lang '${REQUIRED_LANG}'`,
            });
            return z.NEVER;
        }

        return {
            ...data,
            NameRequired: requiredName,
            Name: splitName,
            DurationRequired: requiredDuration,
        };
    });

export const ProgramNoteInfoSchema = z.object({
    Id: zDbId(),
    Note: z.string(),
    CreatedAt: zLuxonDateTime({ iso: true }),
    CreatedBy: AppUserLinkSchema,
});

export const ProgramFormDataSchema = z.object({
    // used only in FE
    InstitutionId: zDbId(),
    Name: z.record(LanguageCodeSchema, ProgramNameFormDataSchema),
    Duration: z.record(LanguageCodeSchema, z.string().optional()),
    ValidFrom: zLuxonDateTime().nullable(),
    ValidTo: zLuxonDateTime().nullable(),
    Rating: z.string().nullish(),
    Competences: zNonEmptyString(),
});

export const ProgramChangeStateFormDataSchema = z.object({
    State: z.nativeEnum(ProgramState),
    Rating: z.string().nullish(),
});

export const ProgramAddFilesFormDataSchema = z.object({
    Files: z.array(zGuid()),
});

export const ProgramNoteFormDataSchema = z.object({
    Note: zNonEmptyString(),
});

export const ProgramRevisionFormDataSchema = z.object({
    Name: z.record(LanguageCodeSchema, ProgramNameFormDataSchema),
    Duration: z.record(LanguageCodeSchema, z.string().optional()),
    ValidFrom: zLuxonDateTime().nullable(),
    ValidTo: zLuxonDateTime().nullable(),
    Rating: z.string().nullish(),
    Competences: zNonEmptyString(),
});

export const FetchProgramsReponseSchema = z.array(ProgramInfoSchema);
export const FetchEnumProgramsReponseSchema = z.array(ProgramLinkSchema);
export const FetchProgramNotesReponseSchema = z.array(ProgramNoteInfoSchema);
export type FetchProgramRevisionInfoResponse = z.infer<typeof FetchProgramRevisionInfoResponseSchema>;
export const FetchProgramRevisionInfoResponseSchema = z.array(ProgramRevisionInfoSchema);

export type ProgramInfo = z.infer<typeof ProgramInfoSchema>;
export type ProgramDetail = z.infer<typeof ProgramDetailSchema>;
export type ProgramRevisionInfo = z.infer<typeof ProgramRevisionInfoSchema>;
export type ProgramNameFormData = z.infer<typeof ProgramNameFormDataSchema>;

export type ProgramNoteInfo = z.infer<typeof ProgramNoteInfoSchema>;
export type FetchProgramsReponse = z.infer<typeof FetchProgramsReponseSchema>;
export type FetchEnumProgramsReponse = z.infer<typeof FetchEnumProgramsReponseSchema>;
export type FetchProgramNotesReponse = z.infer<typeof FetchProgramNotesReponseSchema>;

export type ProgramFormData = z.infer<typeof ProgramFormDataSchema>;
export type ProgramChangeStateFormData = z.infer<typeof ProgramChangeStateFormDataSchema>;
export type ProgramAddFilesFormData = z.infer<typeof ProgramAddFilesFormDataSchema>;
export type ProgramNoteFormData = z.infer<typeof ProgramNoteFormDataSchema>;
export type ProgramRevisionFormData = z.infer<typeof ProgramRevisionFormDataSchema>;
export type ProgramRevisionValidityFlags = z.infer<typeof ProgramRevisionValidityFlags>;
