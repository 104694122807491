import { DateTime, DateTimeFormatOptions, Settings } from 'luxon';
import i18nInstance from '.';
import { Locale } from './_types';
import { changeQuasarLocale } from '~/plugins/quasar/_plugin';
import { DATETIME_LANG } from '~/constants/_constants';

declare module 'luxon/src/datetime' {
    export interface DateTime {
        toDefaultLocaleString(formatOpts?: DateTimeFormatOptions): string;
    }
}

export const changeLocale = (newLocale: Locale) => {
    changeI18nLocale(newLocale);
    changeQuasarLocale(newLocale);
    changeLuxonLocale(newLocale);
};

/**
 * Changes locale for i18n
 * @param newLocale
 */
const changeI18nLocale = (newLocale: Locale) => {
    i18nInstance.global.locale.value = newLocale;
};

/**
 * set default language for luxon (does not affect already created instances)
 * @param newLocale
 */
const changeLuxonLocale = (newLocale: Locale) => {
    Settings.defaultLocale = newLocale;
};

DateTime.prototype.toDefaultLocaleString = function (formatOpts) {
    return this.toLocaleString(formatOpts, { locale: DATETIME_LANG });
};
