import { isEmpty } from 'lodash-es';
import type { AppFileInfo } from '~/api/files/_types';
import type { ProgramDetail, ProgramInfo, ProgramRevisionInfo } from '~/api/programs/_types';
import { InformationCardItemConfig } from '~/components/base/cards/_types';
import { type FilterCardColumnConfig, FilterDataType } from '~/components/base/filters/_types';
import type { AppTableColumnConfig } from '~/components/base/tables/_types';
import { EMPTY_DATA_PLACEHOLDER } from '~/constants/_constants';
import { t } from '~/plugins/i18n';

export const getProgramRevisionsColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'Name',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.ProgramName' },
        field: (val: ProgramRevisionInfo) => val.NameRequired.Orig,
        align: 'left',
    },
    {
        name: 'State',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.State' },
        field: (row) => t(`enums.programState.${row.State}`),
        align: 'left',
    },
    {
        name: 'Rating',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.Rating' },
        field: 'Rating',
        align: 'left',
    },
    {
        name: 'Competences',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.Competences' },
        field: 'Competences',
        align: 'left',
    },
    {
        name: 'Duration',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.Duration' },
        field: (row: ProgramRevisionInfo) => row.DurationRequired,
        align: 'left',
    },
    {
        name: 'ValidFrom',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.ValidFrom' },
        field: (row: ProgramRevisionInfo) => row.ValidFrom?.toDefaultLocaleString(),
        align: 'left',
    },
    {
        name: 'ValidTo',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.ValidTo' },
        field: (row: ProgramRevisionInfo) => row.ValidTo?.toDefaultLocaleString(),
        align: 'left',
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getProgramsColumnConfig = (canSort: boolean): AppTableColumnConfig[] => [
    {
        name: 'Name',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.ProgramName' },
        field: (val: ProgramInfo) => val.NameRequired.Orig,
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'InstitutionName',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.InstitutionName' },
        field: (val: ProgramInfo) => val.Institution.Name,
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'CreatedAt',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.CreatedAt' },
        field: (val: ProgramDetail) => val.CreatedAt.toDefaultLocaleString(),
        align: 'left',
        sortable: canSort,
    },
    {
        name: 'CertifiedAt',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.CertifiedAt.eq' },
        field: (val: ProgramDetail) => val.CertifiedAt?.toDefaultLocaleString(),
        align: 'center',
        sortable: canSort,
    },
    {
        name: 'State',
        label: '',
        translatedLabel: { key: 'programs.overview.columns.State' },
        field: 'State',
        align: 'left',
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getProgramsFilterColumnConfig = (): FilterCardColumnConfig[] => [
    {
        dataType: FilterDataType.Date,
        mode: 'range',
        key: 'CertifiedAt',
        labelFrom: 'programs.overview.columns.CertifiedAt.from',
        labelTo: 'programs.overview.columns.CertifiedAt.to',
        additionalFilter: false,
    },
    {
        dataType: FilterDataType.Enum,
        key: 'InstitutionId',
        label: 'programs.overview.columns.InstitutionName',
        additionalFilter: false,
        multiple: true,
        autocomplete: true,
        optionsKey: 'DisplayId',
        optionsLabelKey: 'Name',
    },
    {
        dataType: FilterDataType.Enum,
        key: 'State',
        label: 'programs.overview.columns.State',
        additionalFilter: false,
        multiple: true,
    },
];

export const getProgramFilesColumnConfig = (): AppTableColumnConfig[] => [
    {
        name: 'DisplayName',
        label: '',
        translatedLabel: { key: 'programs.detail.files.columns.DisplayName' },
        field: 'DisplayName',
        align: 'left',
    },
    {
        name: 'LanguageCode',
        label: '',
        translatedLabel: { key: 'programs.detail.files.columns.LanguageCode' },
        field: 'LanguageCode',
        align: 'left',
    },
    {
        name: 'Extension',
        label: '',
        translatedLabel: { key: 'programs.detail.files.columns.Extension' },
        field: 'Extension',
        align: 'left',
    },
    {
        name: 'CreatedAt',
        label: '',
        translatedLabel: { key: 'programs.detail.files.columns.CreatedAt' },
        field: (row: AppFileInfo) => row.CreatedAt.toDefaultLocaleString(),
        align: 'left',
    },
    {
        name: 'User',
        label: '',
        translatedLabel: { key: 'programs.detail.files.columns.User' },
        field: (row: AppFileInfo) => row.User.FullName,
        align: 'left',
    },
    {
        name: 'Actions',
        label: '',
        field: '',
        align: 'right',
    },
];

export const getProgramsInfoCardConfig = (program: ProgramDetail): InformationCardItemConfig[] => [
    {
        label: 'programs.detail.info.labels.Institution',
        value: program.Institution.Name,
        position: {
            xs: { row: 0, col: 0 },
            sm: { row: 0, col: 0, span: 3 },
        },
    },
    {
        label: 'programs.detail.info.labels.Rating',
        value: program.Rating ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 2, col: 0 },
            sm: { row: 1, col: 0 },
        },
    },
    {
        label: 'programs.detail.info.labels.CreatedAt',
        value: program.CreatedAt.toDefaultLocaleString(),
        position: {
            xs: { row: 3, col: 0 },
            sm: { row: 1, col: 1 },
        },
    },
    {
        label: 'programs.detail.info.labels.CertifiedAt',
        value: program.CertifiedAt?.toDefaultLocaleString() ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 4, col: 0 },
            sm: { row: 1, col: 2 },
        },
    },
    {
        label: 'programs.detail.info.labels.ValidFrom',
        value: program.ValidFrom?.toDefaultLocaleString() ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 5, col: 0 },
            sm: { row: 2, col: 0 },
        },
    },
    {
        label: 'programs.detail.info.labels.ValidTo',
        value: program.ValidTo?.toDefaultLocaleString() ?? EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 6, col: 0 },
            sm: { row: 2, col: 1 },
        },
    },
    {
        label: 'programs.detail.info.labels.State',
        value: t(`enums.programState.${program.State}`),
        valueClass: `text-bold a-text-22 enum-text-programState--${program.State}`,
        position: {
            xs: { row: 7, col: 0 },
            sm: { row: 2, col: 2 },
        },
    },
    {
        label: 'programs.detail.info.labels.Competences',
        value: !isEmpty(program.Competences) ? program.Competences : EMPTY_DATA_PLACEHOLDER,
        position: {
            xs: { row: 8, col: 0 },
            sm: { row: 3, col: 0, span: 3 },
        },
    },
];
